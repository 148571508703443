<template>
	<v-container>					
		<div class="container-project">
			<div v-if="card.length > 0">
				<CardPorjects v-for="(item, i) in card" left :key="i" :link="item.link" :data="item" />
			</div>
			<div v-else>
				<v-alert border="left" type="info">
					No projects were found
				</v-alert>
			</div>
		</div>		
	</v-container>
</template>

<script>
import CardPorjects from "../../components/CardProjects.vue";

export default {
	name: "ProjectsPage",
    components: {
        CardPorjects
    },
	data()
	{
		return {
			card: [
				{
					title: "Mindways",
					text: "A foggy train station, a train without an apparent destination, a girl searching for something. “What’s the reason I'm here after all?” she thinks to herself while looking at that plain and boring ceiling that she’s so familiar with, “I-..It should have a reason...right?”",
					img: "//abismostudios.com.br/mindways/assets/img/background_mindways.png",
					to: "//abismostudios.com.br/mindways",
					target: "_blank",
					link: true
				}
			]
		}
	}
};
</script>

<style scoped>

.container-project {
	max-width: 1000px;
    margin: 45px auto;
}

</style>