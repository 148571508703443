<template>
	<v-card shaped elevation="10">
		<v-row v-if="left == true">
			<v-col lg="7">
				<div style="height: 100%">
					<div class="card-image-custom"></div>
					<v-img
						width="100%"
						height="100%"
						:src="data.img"
					/>
				</div>
			</v-col>
			<v-col lg="5">
				<div class="card-texto-custom">
					<h3 class="card-title-custom">{{ data.title }}</h3>
					<v-card-subtitle class="text-justify mr-5">
						{{ data.text }}
					</v-card-subtitle>
					<div class="card-texto-curstom-fooder text-right">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn v-if="!link" :to="data.to" :target="data.target || ''" large v-bind="attrs" v-on="on" icon>
									<v-icon>mdi-eye</v-icon>
								</v-btn>
								<v-btn v-else @click="linkExterno(data.to, data.target)" large v-bind="attrs" v-on="on" icon>
									<v-icon>mdi-eye</v-icon>
								</v-btn>
							</template>
							<span>Visualize</span>
						</v-tooltip>
					</div>
				</div>
			</v-col>
		</v-row>
		<v-row v-else-if="right == true">
			<v-col lg="5">
				<div class="card-texto-custom">
					<h3 class="card-title-custom">{{ data.title }}</h3>
					<v-card-subtitle style="padding-left: 28px">
						{{ data.text }}
					</v-card-subtitle>
					<div class="card-texto-curstom-fooder text-left" style="padding-left: 12px">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn v-if="!link" :to="data.to" :target="data.target || ''" large v-bind="attrs" v-on="on" icon>
									<v-icon>mdi-eye</v-icon>
								</v-btn>
								<v-btn v-else @click="linkExterno(item.to, item.target)" large v-bind="attrs" v-on="on" icon>
									<v-icon>mdi-eye</v-icon>
								</v-btn>
							</template>
							<span>Visualizer</span>
						</v-tooltip>
					</div>
				</div>
			</v-col>
			<v-col lg="7">
				<div style="height: 100%">
					<div class="card-image-custom"></div>
					<v-img
						width="100%"
						height="100%"
						:src="data.img"
					/>
				</div>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
export default {
	name: "CardProjects",
	props: {
		left: {
			type: Boolean,
			default: false,
		},
		right: {
			type: Boolean,
			default: false
		},
		data: {
			type: Object,
			require: true
		},
		link: {
			type: Boolean,
			require: false,
			default: false
		}
	},
	created() {
		this.left = (this.right == false) ? true : false;
	},
	methods: {
		linkExterno(herf, target = null) {
			window.open(herf, target);
		}
	}
};
</script>

<style scoped>
.v-card {
	margin-bottom: 45px;
	overflow: hidden;
}

.card-image-custom {
	z-index: 5;
	position: absolute;
	bottom: 0px;
	right: 0px;
	top: 0px;
	width: 30%;
	height: 100%;
}

.card-title-custom {
	text-align: center;
	margin: 0 0 1em 0;
	padding-top: 1.5em;
	line-height: 2em;
}

.card-texto-custom {
	position: relative;
	height: 100%;
	width: 100%;
	padding-bottom: 64px;
}

.card-texto-curstom-fooder {
	position: absolute;
	bottom: 16px;
	left: 16px;
	right: 16px;
}

.col {
	padding-top: 0px;
	position: relative;
	padding-bottom: 0px;
}
</style>